import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/PostLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lines of Code is a lousy metric — at least on a small scale. I accept that
it may be useful to compare big projects or repositories, but most often,
when I encounter it, it is just meaningless.`}</p>
    <h2 {...{
      "id": "the-big-scale",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-big-scale",
        "aria-label": "the big scale permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`The Big Scale`}</h2>
    <p>{`It may be valuable on a big scale.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Google Stores Billions of Lines of Code in a Single Repository`}</p>
    </blockquote>
    <p>{`A billion is a big number. This is meaningful. We can state things and ask
questions about it.`}</p>
    <ul>
      <li parentName="ul">{`Google has a huge repository. Maybe even the biggest one in the world.`}</li>
      <li parentName="ul">{`Did they modify git/svn/Perforce to make it possible?`}</li>
      <li parentName="ul">{`My workflow is git-centric. I’m curious about how this repository affects
their workflow? How is it different from mine?`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`The Linux kernel has around 27.8 million lines of code in its Git
repository`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`The Linux kernel is way smaller than the Google codebase.`}</li>
      <li parentName="ul">{`It’s still a tremendous effort.`}</li>
      <li parentName="ul">{`But hey. Isn’t it too big for a “kernel”? Oh! The drivers are there. Makes
sense!`}</li>
    </ul>
    <p>{`Developers seem to care about lines of code much more than I’d expect.`}</p>
    <h2 {...{
      "id": "why-care-about-loc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-care-about-loc",
        "aria-label": "why care about loc permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Why care about LoC?`}</h2>
    <p>{`I heard a story about a programmer paid by lines of code. Hillarious. I hope
it was a joke. This is like paying a construction worker by the materials he
uses! Lines of code have no intrinsic value. This is a cost we pay, a
ballast.`}</p>
    <p>{`We can use it as an approximation of the effort we already invested. Better
yet, we can take numbers of added and deleted lines of code through a
timespan and compare it to LoC of the entire repository, so we get the idea
of how the project is changing over time. I’d argue that a changelog or a
list of work items (Jira tickets, PRs merged?) gives us more information.`}</p>
    <p>{`On its own, LoC of a file for example — It’ just a number. Sad and lonely.`}</p>
    <h2 {...{
      "id": "150---75-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#150---75-",
        "aria-label": "150   75  permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`150 🍎 `}{`<`}{` 75 🍊`}</h2>
    <p>{`Can we say that a 500 line long file is long? Or short? Or is it just
perfect?`}</p>
    <p>{`We can’t and this would be pointless. Don’t let
`}<a parentName="p" {...{
        "href": "https://eslint.org/docs/rules/max-lines"
      }}>{`any lint rule`}</a>{` tell you otherwise.
We need more information. The language isn’t enough. Let’s take modern
JavaScript.`}</p>
    <p>{`Is it declarative, imperative, or functional? The answer may differ between
files in the same repository, and when we’re actually close enough to read
what’s inside the file, we may form better models to think about it than
LoC.`}</p>
    <p>{`Let me continue with the declarative. Declarative is easier to read than
imperative, right? We don’t have to think as much while reading, because it
just `}<strong parentName="p">{`is`}</strong>{`, while imperative `}<strong parentName="p">{`does`}</strong>{`. For example, HTML is easier to read
than JavaScript of the same length.`}</p>
    <p>{`In a React class component, 150 lines of JSX in a `}<inlineCode parentName="p">{`render`}</inlineCode>{` function is “less
code” than 75 lines of class logic.`}</p>
    <p>{`I will go further. 150 lines of JSX in a functional component is “less code”
than 75 lines of hooks. `}<inlineCode parentName="p">{`useState`}</inlineCode>{`, `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`, `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{`,
`}<inlineCode parentName="p">{`useSelector`}</inlineCode>{`, `}<inlineCode parentName="p">{`useMachine`}</inlineCode>{`. A lot happens there. The difference may not be
as big as when comparing declarative UI composition with imperative code in
lifecycle hooks, but I’d argue that it still holds. We have fewer things to
comprehend in JSX because much of it is self-explanatory. (Go away
`}<inlineCode parentName="p">{`<Fetch />`}</inlineCode>{` component, you’re the outlier.)`}</p>
    <p>{`This is all JavaScript, but aren’t we comparing apples to oranges?`}</p>
    <p>{`There are different `}<em parentName="p">{`kinds`}</em>{` of code.`}</p>
    <ul>
      <li parentName="ul">{`Declarative, but non-functional code, meaning HTML, CSS, SQL and GraphQL
may be verbose, but it’s trivial to read.`}</li>
      <li parentName="ul">{`Imperative code will certainly be harder to read, and way harder to
maintain.`}</li>
      <li parentName="ul">{`Functional code doing the same thing may be more concise and easier to
debug, but require detailed reading at first.`}</li>
    </ul>
    <p>{`We can divide it in more ways! In the same language, the same codebase,
there will be some important code and some cheap code. The text doesn’t hold
this information.`}</p>
    <h2 {...{
      "id": "better-metrics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#better-metrics",
        "aria-label": "better metrics permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Better Metrics`}</h2>
    <p>{`We would like to measure things that matter, obviously.`}</p>
    <p>{`Ease of comprehension is a good one. It is a very soft thing, though. Can we
measure something easier and assume it’s correlated with cognitive
complexity?`}</p>
    <p>{`Enter
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Cyclomatic_complexity"
      }}><strong parentName="a">{`cyclomatic complexity`}</strong></a>{`,
a measure of the number of linearly independent paths in a program’s control
flow graph.`}</p>
    <p>{`The more paths we have, the more we need to think about, and what’s
important, the more we have to test.`}</p>
    <h2 {...{
      "id": "further-reading",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#further-reading",
        "aria-label": "further reading permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Further reading`}</h2>
    <ul>
      <li parentName="ul">{`SonarSource has a nice heuristic for cognitive complexity. I didn’t read
their whitepaper thoroughly, but it makes a lot of sense. This is the rule
I’m using in my ESLint config.`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://blog.sonarsource.com/cognitive-complexity-because-testability-understandability"
            }}>{`https://blog.sonarsource.com/cognitive-complexity-because-testability-understandability`}</a></li>
        </ul></li>
      <li parentName="ul">{`“Danger of Simplicity” is a good read`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://asthasr.github.io/posts/danger-of-simplicity/"
            }}>{`https://asthasr.github.io/posts/danger-of-simplicity/`}</a></li>
        </ul></li>
    </ul>
    <TableOfContents mdxType="TableOfContents" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      